import request from '@/utils/request'

// 查询客户月账单数据
export function findAgentMonthlyBills(params) {
  return request({
    url: `/agent_monthly_bills`,
    method: 'get',
    params: params
  })
}

// 导出分销商月账单数据
export function exportAgentMonthlyBills(data) {
  return request({
    url: `/agent_monthly_bills/export`,
    method: 'post',
    data
  })
}

// 查询客户月账单详情
export function findAgentMonthlyBill(id) {
  return request({
    url: `/agent_monthly_bills/${id}`,
    method: 'get'
  })
}

// 查询客户月账单采购账单列表
export function findPurchaseBills(id, params) {
  return request({
    url: `/agent_monthly_bills/${id}/purchase_bills`,
    method: 'get',
    params: params
  })
}

// 查询客户月账单流量使用账单列表
export function findCardUseBills(id, params) {
  return request({
    url: `/agent_monthly_bills/${id}/product_usage_orders`,
    method: 'get',
    params: params
  })
}

// 查询客户月账单停机保号账单列表
export function findStopBills(id, params) {
  return request({
    url: `/agent_monthly_bills/${id}/product_stop_orders`,
    method: 'get',
    params: params
  })
}

// 查询企业客户月账单数据
export function findAgencyAgentMonthlyBills(params) {
  return request({
    url: `/customer/agent_monthly_bills/agency`,
    method: 'get',
    params: params
  })
}

// 查询企业客户月账单详情
export function exportAgencyAgentMonthlyBills(data) {
  return request({
    url: `/customer/agent_monthly_bills/agency/export`,
    method: 'post',
    data
  })
}

// 查询企业客户月账单详情
export function findAgencyAgentMonthlyBill(id) {
  return request({
    url: `/customer/agent_monthly_bills/agency/${id}`,
    method: 'get'
  })
}

// 查询客户月账单划拨账单列表
export function findAgentAllocationBills(id, params) {
  return request({
    url: `/customer/agent_monthly_bills/${id}/allocation_bills`,
    method: 'get',
    params: params
  })
}

// 查询客户月账单续期账单列表
export function findRenewBills(id, params) {
  return request({
    url: `/agent_monthly_bills/${id}/renew_bills`,
    method: 'get',
    params: params
  })
}

// 查询客户月账单激活账单列表
export function findActiveBills(id, params) {
  return request({
    url: `/agent_monthly_bills/${id}/active_bills`,
    method: 'get',
    params: params
  })
}

// 查询客户月账单订购账单列表
export function findSubscribeCardBills(id, params) {
  return request({
    url: `/agent_monthly_bills/${id}/subscribe_bills`,
    method: 'get',
    params: params
  })
}

// 查询客户月账单订购流量池账单列表
export function findSubscribeTrafficPoolBills(id, params) {
  return request({
    url: `/agent_monthly_bills/${id}/subscribe_traffic_pool_bills`,
    method: 'get',
    params: params
  })
}
